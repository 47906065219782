export default class Analyser {

    static permission_levels = {
        view: 1,
        edit: 2,
        create: 3,
        delete: 4
     };
    
    static can(action, permission_type = null, user) {
        if (user?.admin || !permission_type) {
            return true
        }

        return (user?.role?.permissions?.[permission_type] ?? 0) >= this.permission_levels[action]
    }

}