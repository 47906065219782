<template>
  <div
    :class="{
      'bg-red-100': error_message,
      'bg-secondary-200': !error_message,
      modal__formfooter: form,
      modal__footer: !form,
    }"
  >
    <div class="flex items-center justify-end gap-4">
      
      <slot></slot>
      
      <button
        class="
          inline-flex
          items-center
          px-4
          py-2
          bg-gray-800
          border border-transparent
          rounded-md
          font-semibold
          text-xs text-white
          uppercase
          tracking-widest
          hover:bg-gray-700
          active:bg-gray-900
          focus:outline-none focus:border-gray-900 focus:ring
          ring-gray-300
          disabled:opacity-25
          transition
          ease-in-out
          duration-150
        "
        @click.prevent="$emit('close')"
      >
        Cancel
      </button>

      <button
        @click="$emit('clicked', false)"
        type="submit"
        class="button button-secondary button-sm
        "
      >
        <spinner-icon
          v-if="show_spinner"
        ></spinner-icon>
        {{ buttonLabel }}
      </button>

      <button
        @click="$emit('clicked', true)"
        type="submit"
        class="button button-secondary button-sm
        "
        v-if="!edit && can_renew"
      >
        <spinner-icon
          v-if="show_spinner"
        ></spinner-icon>
        {{ !show_spinner ? "Create + New" : "Creating..." }}
      </button>
    </div>

    <form-error-message :error_message="error_message" :errors="errors" />
  </div>
</template>

<script>
import FormErrorMessage from "./FormErrorMessage.vue";
import SpinnerIcon from "./icons/SpinnerIcon.vue";
export default {
props: ["show_spinner", "error_message", "errors", "edit", "delete", "form", "can_renew", "custom_label"],
  components: {
    FormErrorMessage,
    SpinnerIcon,
  },
  emits: ["clicked", "close"],
  computed: {
    buttonLabel() {
      if(this.delete) {
        return !this.show_spinner ? "Delete" : "Deleting...";
      }
      else if (this.custom_label?.length)
      {
        return !this.show_spinner ? this.custom_label[0] : `${this.custom_label[1]}...`;
      }
      else if (this.edit) {
        return !this.show_spinner ? "Update" : "Updating...";
      } 
      else {
        return !this.show_spinner ? "Create" : "Creating...";
      }
    },
  },
};
</script>